export default class Utils {
  static isNotNullOrEmpty(value) {
    return value !== null && value !== undefined && value !== "";
  }
  static isAValidNumber(value) {
    value = parseFloat(value);
    return !isNaN(value) && value > 0;
  }
  static isValidCurrency(str) {
    console.log(str);
    // Regular expression to match a positive decimal number with two decimal places
    const regex = /^\d+(\.\d{2})?$/;

    // Use the test() method to check if the string matches the regular expression
    return regex.test(str);
  }
  static getContrastColor(hexColor) {
    // Convert the hexadecimal color to RGB components
    const r = parseInt(hexColor.slice(1, 3), 16) / 255;
    const g = parseInt(hexColor.slice(3, 5), 16) / 255;
    const b = parseInt(hexColor.slice(5, 7), 16) / 255;

    // Calculate the relative luminance
    const L = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    // Choose the text color based on the contrast ratio
    return L > 0.5 ? "#000000" : "#FFFFFF";
  }
}
